.navbar-light {
  background-color: #ffffff;
}

.footer {
  background-color: white;
}

.jum {
  margin: 0px;
}

.bg-card {
  /* background: url("/assets/bg.svg") no-repeat;
  background-size: 100%; */
  /* height: 80%;
  width: 80%; */
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 25%;
  /* margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  margin-bottom: 10%; */
}

.notes-hint,
.fullscreen-button {
  text-align: end;
}
.fullscreen-button {
  padding: 0;

  .cursor {
    float: right;
  }
}

.exit-fullscreen {
  z-index: 999;
  position: fixed;
  top: 24px;
  right: 36px;
  cursor: pointer;
  font-size: 24px !important;
  opacity: 0.6;
  border: solid 1px white;
  background-color: white;
  padding: 8px 8px 8px 10px;
  border-radius: 4px;
  color: #777;
  transition: opacity 0.3s, border 0.15s;
}
.exit-fullscreen:hover {
  color: #555;
  border: solid 1px #aaa;
  opacity: 0.8;
}

@media only screen and (max-width: 1200px) {
  .bg-card {
    /* background: url("/assets/bg_small.svg") no-repeat;
    background-size: 100%;
    background-color: #476bd0; */
  }
}

@media only screen and (max-width: 680px) {
  .bg-card {
    /* background: url("/assets/bg_small.svg") no-repeat;
    background-size: 100%;
    background-color: #476bd0; */
    width: 100%;
    height: 95%;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 5%;
    margin-bottom: 0%;
  }
}

.code-card {
  background-color: #476bd0;
  background-size: 100%;
  height: 80%;
  width: 100%;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4%;
}

@media only screen and (max-width: 1024px) {
  .notes-hint,
  .fullscreen-button {
    text-align: start;
  }
  .code-card {
    margin-left: 0%;
    margin-right: 0%;
    background-color: #476bd0;
    background-size: 100%;
    height: 80%;
    width: 100%;
    padding-right: 5%;
    padding-bottom: 6%;
  }
}

.cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.modal-dialog {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.pagination {
  justify-content: center;
  margin: 30px;
}

.history-tab {
  padding-right: 10%;
  box-shadow: 0 4px 8px 0 rgba(252, 248, 248, 0.2), 0 6px 20px 0 rgba(231, 226, 226, 0.19);
  cursor: pointer;
}

.notes-area {
  background-color: rgb(255, 255, 205);
}
.notes-area:focus {
  background-color: rgb(255, 255, 205);
}

label {
  margin-right: 10px;
}
.CodeMirror-placeholder {
  color: #999 !important;
}

.card-cursor {
  color: #476BD0;
}

.fa.fa-user {
  color: #808080;
}

/* Home page */
.master {
  color: white;
  width: 100%;
  overflow: hidden;
  background-image: url(/assets/bg-homepage.webp);
  background-repeat: no-repeat;
  background-position: 45% center;
  background-size: cover;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.the-strip {
  background-color: #80808088;
}

.bound {
  width: 87%;
  max-width: calc(1000 * 1px);
  margin: auto;
}

.intro {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.intro-name {
  padding-left: 1rem; /* To align with navbar */
  flex: 1;
  font-size: 2.333rem;
  font-weight: 500;
  margin-top: 33px;
}

.intro-tag {
  padding-left: 1rem; /* To align with navbar */
  font-size: 6.999rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 23px;
  border-bottom:1px solid white;
}

.intro-tag span {
  display: block;
}

.new-bottom {
  padding-left: 1rem; /* To align with navbar */
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.home-footer {
  background-color: transparent;
}

.home-footer a {
  color: inherit;
}

/* General */
div#root {
  height: 100%
}
