.one-hint {
    position: fixed;
    bottom: 2px;
    left: 0;
    right: 0;
    z-index: 99;
}
.one-hint>.alert {
    max-width: 30em;
    margin: 0 auto;
}
.one-hint .fa {
    margin-right: 8px;
}
.one-code-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 350px;
}
.code-card .navbar {
    border-bottom: thin solid #9bf;
}
.one-code-container>.code-card {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 8% 0px 8%;
}
.code-card>* {
    margin: 4px 0;
}
.code-card>.codearea {
    flex: 1 1;
}

.one-code-container .CodeMirror {
  min-height: 100%;
}

.one-code-container .ReactCodeMirror {
    height: 100%;
}

.footer-bar .align-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
