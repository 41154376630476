.one-header-menu .fa-sign-out {
    margin-left: 8px;
}
.one-header-brand {
    padding-right: 12px;
}
.one-header-menu button.navbar-toggler,
.menu-card .nav-link,
.menu-card .navbar-text {
    color: white;
    outline: none;
    transition: 0.2s;
}
.menu-card button.navbar-toggler:hover {
    border: 1px solid #eee;
}
.navbar{
  padding-right: 0;
  padding-left: 10px;
}
