.one-nav-bar {
  display: flex;
  width: 100%;
}
.one-nav-item {
  flex: 1 0;
  border-radius: 3px;
  border:1px solid transparent;
  margin-right: 4px;
  text-align: center;
  padding: 4px 0;
  background-color:rgba(40, 60, 130, 0.5);
  color: #f8f8f8;
  cursor: pointer;
  transition-duration: 0.3s;
}
.one-nav-item:last-child {
  margin-right: 0;
}
.one-nav-item.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #679bf0;
}


.his-card {
background-color: #476bd0;
background-size: 100%;
height: 100%;
width: 100%;
padding-top: 3%;
padding-left: 5%;
padding-right: 5%;
padding-bottom: 4%;
margin: auto;
display: flex;
flex-direction: column;
overflow-y: auto;
}

@media only screen and (max-width: 680px) {
.his-card {
  background-color: #476bd0;
  background-size: 100%;
  height: 100%;
  width: 100%;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4%;
  margin: 0;
}
}

.empty-interview {
font-size: 1.1em;
color: #555;
background-color: white;
padding: 13% 0;
border-radius: 2px;
margin-top: 16px;
text-align: center;
}
.empty-interview>img {
  margin: 12px;
}

.tab-content {
flex: 1 1 auto; /*3% padding, nav&tab 100px */
}

.tab-content>.active {
height: 100%
}

.history-cards {
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
}

.pagination-wrapper {
text-align: center;
}
